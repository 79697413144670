body {
    margin: 0;
    margin-bottom: 35px;
    padding: 0;
    overflow-x: hidden;
    font-family: 'Source Code Pro', monospace;
}


.menu {
    position: absolute;
    top:0;
    display: flex;
    justify-content: space-around;
    width: 100vw;
    padding: 0;
}

.menu > li {
    list-style-type: none;
    cursor: pointer;
    z-index:1;
}

.menu > li > * {
    text-decoration: none;
    font-size: 24px;
    color: black;
    text-shadow: 1px 1px 0 rgb(255, 255, 255),
        -1px 1px 0 rgb(255, 255, 255),
        -1px -1px 0 rgb(255, 255, 255),
        1px -1px 0 rgb(255, 255, 255);
    
}

.background { 
    width: 100vw;
    height: 80vh;
    position:absolute;
    top:0px;
    background-size:cover;
    -webkit-animation: slidein 100s;
    animation: slidein 100s;

    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;

    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;

    -webkit-animation-direction: alternate;
    animation-direction: alternate; 
}



@-webkit-keyframes slidein {
    from {background-position: top; background-size:3000px; }
    to {background-position: -100px 0px;background-size:2750px;}
}

@keyframes slidein {
    from {background-position: top;background-size:3000px; }
    to {background-position: -100px 0px;background-size:2750px;}
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(34, 34, 116, 0.397);
  border-radius: 3px;
  height: 80vh
}

.center h1 {
  text-align:center;
  color:white;
  font-family: 'Source Code Pro', monospace;
  text-transform:uppercase;
  text-shadow: -1px -1px 1px #000,
        1px 1px 2px #000,
        1px -1px 1px #000,
        -1px 1px 2px #000;
}

.center p {
    text-shadow: 1px 1px 0 rgb(255, 255, 255),
    -1px 1px 0 rgb(255, 255, 255),
    -1px -1px 0 rgb(255, 255, 255),
    1px -1px 0 rgb(255, 255, 255);
}

.bio {
    display: flex;
    flex-direction: column;
    align-items: center;
    width:  80vw;
    position: absolute;
    top:81vh;
    left:10vw;
}

.bio > hr {
    width: 80vw;
}

.portfolio {
    display: flex;
    flex-direction: column; 
    align-items: center;
    margin-top: 80px;
}

.portfolio hr {
    width:100vw;
    color: black;
    margin-bottom:30px;
}

.projectholder {
    display: grid;
    grid-template-columns: 25vw 25vw;
    grid-template-rows: 2fr 2fr;
    gap:30px;
    align-items: center;
}

.projectholder > a {
    text-decoration: none;
    color: black;
    cursor: pointer;
}

.project {
    border: 1px solid black;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    box-shadow: 0px 35px 40px rgba(0, 0, 0, 0.397);
}

.project > img {
    width: 20vw;
}

.others {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.others > h1 {
    margin-bottom: 30px;
}

.otherproject > a {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: black;
}

.otherproject hr {
    width: 40vw;
}

.contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
}